import React from "react";

export default (props) => (
    <div className="page-header">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3> { props.title } </h3>
                </div>
            </div>
        </div>
    </div>
);