import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from './../components/portfolio-banner/PortfolioBanner'
import Advantages from './../components/advantages/Advantages'
import SimpleText from './../components/simple-text/SimpleText'
import JobOffert from './../components/job-offert/JobOffert'
import PageHeader from './../components/page-header/PageHeader'
import SEO from '../components/seo'

class PostTemplate extends Component {
  constructor(props) {
    super(props)
  }

  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-245px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.acf.banner} />
        <div className="take-up">
          <SimpleText text={currentPage.content} />
          <Advantages data={currentPage.acf.advantages} cols={3} />
          <PageHeader title={'Obecnie szukamy'} />
          <JobOffert oferts={currentPage.acf.work_offers} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        work_offers {
          post_title
          post_excerpt
          post_name
        }
        banner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
                srcSet
              }
            }
          }
        }
        advantages {
          text
          icon {
            wordpress_id
            alt
            url {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
