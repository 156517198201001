import React, {  Component } from 'react';
import JobOffertItem from './JobOffertItem';
import SeeOther from './../see-other/SeeOther';

class JobOffert extends Component {
    render() {
        return(
            <section className="job-offerts">
                <div className="container">
                    <div className="row">
                        <div className="job-offerts-wrap ">
                            { (this.props.oferts ? this.props.oferts.map(item => (
                                <JobOffertItem data={item} />
                            )) : null)}
                        </div>
                        <SeeOther title={ 'Zobacz wszystkie oferty' } link={ '/znajdz-prace' } buttonTitle={ 'Zobacz oferty' } />
                    </div>
                </div>
            </section>
        );
    }
}

export default JobOffert;