import React from "react";
import { Link } from 'gatsby';

export default (props) => (
    <div className="job">
        <div className="border-wrap">
            <h2>{ props.data.post_title }</h2>
            <p dangerouslySetInnerHTML={ {__html: props.data.post_excerpt} }></p>
            <Link to={ '/znajdz-prace/' + props.data.post_name } class="btn">Zobacz ofertę</Link>
        </div>
    </div>
);